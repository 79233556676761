import './Hero.css'


const Hero = () => {



    return (
        <div
        className="hero"
        >
        
            <div class="hero-content">
                <h1>Let's talk tech</h1>
                <p>eBig Design is New York’s top web development firm. We know that every business operates differently, and we will take care of you to make sure your services run smoothly.</p>
                <a href="#about">
                <button id="myBtn"
                >
                    Learn More
                    </button>
                    </a>
                </div>

    
        </div>
    )
}

export default Hero;