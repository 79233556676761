import React, { useState, useEffect } from 'react';
import './Quote.css';

const Quote = () => {
  // Array of quotes
  const quotes = [
    {
      text: '“In a few months..., eBig Design NYC was able to make it eye-catching or appealing to anyone who visits the website.”',
      author: 'Edgar O.',
      position: 'CEO and Founder of PPW',
    },
    {
      text: '“Mono would not be what it is today without you. Thank you so much for the opportunity to learn from you and your marketing and community expertise.”',
      author: 'James P.',
      position: 'Founder of Mono Nomads',
    },
    {
      text: '“eBig Design NYC has done a great job of creating a marketing campaign that bring eyes to my brand. The team is thoughtful in their work and is constantly fostering new relationships that benefitted not only me, but their other clients as well. eBig is hardworking, creative, and efficient. I highly recommend them.”',
      author: 'Orion P.',
      position: 'Founder and CEO of Tempered Minds Entertainment',
    },
    {
      text: '“eBig Design NYC is always on the go and eager to learn new ideas/experiences!”',
      author: 'Jimmy L.',
      position: 'VP of Operations at PPW',
    },
  ];

  // State to manage the current quote index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically cycle through quotes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000); // Change quote every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [quotes.length]);

  // Handle manual navigation
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="quote-slideshow">
      <div className="quote-content fade">
        <h2>{quotes[currentIndex].text}</h2>
        <p>{quotes[currentIndex].author}</p>
        <small>{quotes[currentIndex].position}</small>
      </div>

      {/* Circle Indicators */}
      <div className="quote-indicators">
        {quotes.map((_, index) => (
          <span
            key={index}
            className={`quote-indicator ${currentIndex === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Quote;
