import './Header.css';

function Links() {
    return (
<div className="links">

<a
  href="./#about"
  // target="_blank"
  rel="noopener noreferrer"
>
About
</a>

<a
  href="./#portfolio"
  // target="_blank"
  rel="noopener noreferrer"
>
Portfolio
</a>

<a
  href="./#request"
  // target="_blank"
  rel="noopener noreferrer"
>
Contact
</a>

<a
  href="./#services"
  // target="_blank"
  rel="noopener noreferrer"
>
Services
</a>

</div>
    )
};

export default Links;
