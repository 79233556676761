import React from 'react';
import './Services.css'; // Include a CSS file for styling

function Services() {
  const services = [
    {
      title: 'Social Media Marketing',
      description: 'Engage your audience and grow your brand with targeted social media campaigns.',
      icon: '📱', // You can replace these with real icons or images
    },
    {
      title: 'SEO Optimization',
      description: 'Improve your website’s visibility on search engines and drive organic traffic.',
      icon: '🔍',
    },
    {
      title: 'Content Marketing',
      description: 'Create valuable, high-quality content that resonates with your audience.',
      icon: '✍️',
    },
    {
      title: 'Email Marketing',
      description: 'Reach your customers directly with personalized email campaigns.',
      icon: '📧',
    },
    {
      title: 'PPC Advertising',
      description: 'Drive targeted traffic and maximize ROI with pay-per-click advertising.',
      icon: '💻',
    },
    {
        title: 'Event Planning',
        description: 'Plan and execute memorable events that align with your brand’s vision.',
        icon: '🎉',
      },
      {
        title: 'Brand Strategy',
        description: 'Develop a clear and compelling strategy to define and grow your brand identity.',
        icon: '📈',
      },
      {
        title: 'Influencer Marketing',
        description: 'Leverage influential voices to promote your products and services.',
        icon: '🤝',
      },
  ];

  return (
    <section id="services" className="marketing-services">
      <h2 className="services-title">Our Marketing Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
