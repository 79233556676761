// Portfolio.js
import './Portfolio.css';
import minthenow from './minthenow.png';
import metanin from './metanin.png';
import mono from './mono.png';
import ppw from './ppw.png';
import sugar from './sugar.png';
import thursday from './thursday.png';

const Portfolio = () => {
  const brands = [
    { id: 1, name: 'Metanin Collective', logo: metanin },
    { id: 2, name: 'Minthenow', logo: minthenow },
    { id: 3, name: 'Mono', logo: mono },
    { id: 4, name: 'PPW', logo: ppw },
    { id: 5, name: 'Sugar', logo: sugar },
    { id: 6, name: 'Thursday', logo: thursday },
  ];

  return (
    <div id="portfolio" className="portfolio">
      <h2>Past Works</h2>
      <div className="marquee">
        <div className="marquee-content">
          {/* First Set of Logos */}
          {brands.map((brand) => (
            <div key={brand.id} className="brand-logo">
              <img src={brand.logo} alt={`${brand.name} logo`} />
            </div>
          ))}
          {/* Second Set of Logos (Duplicate) */}
          {brands.map((brand) => (
            <div key={`${brand.id}-duplicate`} className="brand-logo">
              <img src={brand.logo} alt={`${brand.name} logo`} />
            </div>
          ))}

                    {/* Second Set of Logos (Duplicate) */}
                    {brands.map((brand) => (
            <div key={`${brand.id}-triple`} className="brand-logo">
              <img src={brand.logo} alt={`${brand.name} logo`} />
            </div>
          ))}
          
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
