// frontend/src/components/LandingPage.js
import React from 'react';
import Hero from './Hero';
import Header from './Header';
import About from './About';
import Portfolio from './Portfolio';
import USPs from './USPs';
import RequestAFreeConsultation from './RequestAFreeConsultation';
import Services from './Services';
import Quote from './Quote';
import Footer from './Footer';

function LandingPage() {
  
  return (
   
  <React.StrictMode>
    
    <Header />
    <Hero />
    <Portfolio />
    <About />
    <USPs />
    <RequestAFreeConsultation />
    <Services />
    <Quote />
    <Footer />

  </React.StrictMode>  
   
    );

  };
  
  

export default LandingPage;
