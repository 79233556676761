import React, { useState } from 'react';
import './ContactForm.css';

function ContactForm() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.message || 'Error submitting form');
      }

      const data = await res.json();
      setResponse(data.message);
    } catch (error) {
      console.error('Error:', error);
      setResponse("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="contact">
        <h2>Contact Us</h2>
        <form className="contact-content" onSubmit={handleSubmit}>
          <label className="label1">Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />

          <label className="label2">Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />

          <label className="label3">Message:</label>
          <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>

          <button className="contact-myBtn" id="myBtn" type="submit">Submit</button>
        </form>
        {response && <p>{response}</p>}
      </div>
    </>
  );
}

export default ContactForm;
