import './USPs.css'

const USPs = () => {

    return (
        <div className="usps">
                
            <div className="usps-heading">
                <h2>Why choose eBig Design NYC?</h2>
            </div>

            <div className="big-group">
                <div className="small-group-1">
                    <div className="usp1">
                        <h3>High quality delivery</h3>
                        <p> Lightning-fast turnaround time guaranteed to suit your needs </p>
                    </div>
                    <div className="usp2">
                        <h3>Client support</h3>
                        <p> Top-of-the-line, relentless comminication and transparency </p>

                    </div>
                    <div className="usp3">
                        <h3>An equitable and diverse world</h3>
                        <p> Allowing a sliding scale, customized for your convenience </p>
                    </div>
                </div>
                <div className="small-group-2">
                    <div className="usp4">
                        <h3>Consulting</h3>
                        <p> Explore our consultancy services designed specifically for nonprofits
                            and startups. We customize our approach to unleash your inherent potential and guide you on the path to success. </p>

                    </div>
                    <div className="usp5">
                        <h3>The SEO House Program</h3>
                        <p> Experience the empowering influence of knowledge through our digital marketing training.
                            Carefully crafted to enhance skills, instill confidence, and inspire innovation, our training initiatives are designed for your success. </p>

                    </div>
                    <div className="usp6">
                        <h3>Communities</h3>
                        <p> Discover the potential for unparalleled support within every challenge through our collective initiatives.
                            We grasp the intricacies of your requirements and are dedicated to offering timely assistance at every stage.   </p>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default USPs;