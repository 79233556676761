import './Request.css'

const RequestAFreeConsultation = () => {

    return (
        <div
        id="request"
        className="request"
        >
            <div id="contact" class="request-content">
                <h2>Request a free consultation</h2>
                <p> Our first consultation is free. During our conversation, we’ll discuss your business and any technology concerns you may have. Once you make your first payment, all development will happen through us. From that point on, we’ll take care of your technology and answer any questions you may have on your journey. To get started, contact us below.</p>
                <a
                href="tel:5512250079">
                <button
                className="request-myBtn"
                >
                    Contact us
                    </button>
                </a>
            </div>
        </div>
    )
}

export default RequestAFreeConsultation;